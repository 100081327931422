

<template>
    <td>
        <div class="table-responsive" :id="'order'+order.id">
        <table class="table" >
            <tr class="d-flex">
                <td class="col-2"><strong>{{order.customer.first_name+' '+order.customer.last_name}}</strong></td>
                <td class="col-2">{{order.ordered_at}} <br> <small class="text-muted">{{trans('order.data')}}</small></td>
                <td class="col-1">{{ order.extern_id }} <br> <small class="text-muted">{{ trans('order.id') }}</small></td>
                <td class="col-3">{{order.customer.address_line_1 +', '+order.customer.city }} <br> <small class="text-muted">{{trans('order.location')}}</small></td>
                <td class="col-1">{{order.amount_total}} <br> <small class="text-muted">{{trans('order.amount_total')}}</small></td>
                <td class="col-2 d-flex flex-column"><div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{ status }}
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" @click.prevent="changeStatus('Open')">Open</a>
                        <a class="dropdown-item" @click.prevent="changeStatus('In process')">In process</a>
                        <a class="dropdown-item" @click.prevent="changeStatus('Ready for dispatch')">Ready for dispatch</a>
                        <a class="dropdown-item" @click.prevent="changeStatus('Done')">Done</a>
                    </div>
                </div>

                </td>
                <td class="col-1 d-flex flex-column"><button class="btn btn-light" @click="print">Print</button> <button class="btn btn-dark" @click="open=!open">Details</button></td>
            </tr>
            <tr class="d-flex" v-for="product in order.products" v-if="open">
                <td class="col-4">{{product.name}} <br> <small class="text-muted">{{trans('order.product.name')}}</small></td>
                <td class="col-1">{{product.quantity}} <br> <small class="text-muted">{{trans('order.product.quantity')}}</small></td>
                <td class="col-3">{{product.ean_number}} <br> <small class="text-muted">{{trans('order.product.ean_number')}}</small></td>
                <td class="col-4"></td>
            </tr>
        </table>
        </div>
    </td>
</template>
<script>


    export default {
        data() {
            return {
                open: false,
                status: ''
            };
        },
        props: ['order'],
        mounted() {
            this.status = this.order.status;
        },
        methods: {
            changeStatus: function(status){
                let vm = this;
                axios.patch('/api/orders/'+vm.order.id, { status: status } )
                    .then(response => {
                        console.log(response);
                        toastr.success(response.data.message);
                        vm.status = status;
                        this.$emit('completed')
                    })
                    .catch(error => {
                        helper.showErrorMsg(error);
                    });
            },
            print () {
                let vm = this;

                this.$htmlToPaper('order'+vm.order.id);
            }
        }
    }
</script>
<style scoped>
    .table-responsive{
        overflow: visible;
    }
</style>

