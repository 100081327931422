var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("td", [
    _c(
      "div",
      {
        staticClass: "table-responsive",
        attrs: { id: "order" + _vm.order.id }
      },
      [
        _c(
          "table",
          { staticClass: "table" },
          [
            _c("tr", { staticClass: "d-flex" }, [
              _c("td", { staticClass: "col-2" }, [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.order.customer.first_name +
                        " " +
                        _vm.order.customer.last_name
                    )
                  )
                ])
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "col-2" }, [
                _vm._v(_vm._s(_vm.order.ordered_at) + " "),
                _c("br"),
                _vm._v(" "),
                _c("small", { staticClass: "text-muted" }, [
                  _vm._v(_vm._s(_vm.trans("order.data")))
                ])
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "col-1" }, [
                _vm._v(_vm._s(_vm.order.extern_id) + " "),
                _c("br"),
                _vm._v(" "),
                _c("small", { staticClass: "text-muted" }, [
                  _vm._v(_vm._s(_vm.trans("order.id")))
                ])
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "col-3" }, [
                _vm._v(
                  _vm._s(
                    _vm.order.customer.address_line_1 +
                      ", " +
                      _vm.order.customer.city
                  ) + " "
                ),
                _c("br"),
                _vm._v(" "),
                _c("small", { staticClass: "text-muted" }, [
                  _vm._v(_vm._s(_vm.trans("order.location")))
                ])
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "col-1" }, [
                _vm._v(_vm._s(_vm.order.amount_total) + " "),
                _c("br"),
                _vm._v(" "),
                _c("small", { staticClass: "text-muted" }, [
                  _vm._v(_vm._s(_vm.trans("order.amount_total")))
                ])
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "col-2 d-flex flex-column" }, [
                _c("div", { staticClass: "dropdown" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary dropdown-toggle",
                      attrs: {
                        type: "button",
                        id: "dropdownMenuButton",
                        "data-toggle": "dropdown",
                        "aria-haspopup": "true",
                        "aria-expanded": "false"
                      }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.status) +
                          "\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "dropdown-menu",
                      attrs: { "aria-labelledby": "dropdownMenuButton" }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.changeStatus("Open")
                            }
                          }
                        },
                        [_vm._v("Open")]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.changeStatus("In process")
                            }
                          }
                        },
                        [_vm._v("In process")]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.changeStatus("Ready for dispatch")
                            }
                          }
                        },
                        [_vm._v("Ready for dispatch")]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.changeStatus("Done")
                            }
                          }
                        },
                        [_vm._v("Done")]
                      )
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "col-1 d-flex flex-column" }, [
                _c(
                  "button",
                  { staticClass: "btn btn-light", on: { click: _vm.print } },
                  [_vm._v("Print")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-dark",
                    on: {
                      click: function($event) {
                        _vm.open = !_vm.open
                      }
                    }
                  },
                  [_vm._v("Details")]
                )
              ])
            ]),
            _vm._v(" "),
            _vm._l(_vm.order.products, function(product) {
              return _vm.open
                ? _c("tr", { staticClass: "d-flex" }, [
                    _c("td", { staticClass: "col-4" }, [
                      _vm._v(_vm._s(product.name) + " "),
                      _c("br"),
                      _vm._v(" "),
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v(_vm._s(_vm.trans("order.product.name")))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "col-1" }, [
                      _vm._v(_vm._s(product.quantity) + " "),
                      _c("br"),
                      _vm._v(" "),
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v(_vm._s(_vm.trans("order.product.quantity")))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "col-3" }, [
                      _vm._v(_vm._s(product.ean_number) + " "),
                      _c("br"),
                      _vm._v(" "),
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v(_vm._s(_vm.trans("order.product.ean_number")))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "col-4" })
                  ])
                : _vm._e()
            })
          ],
          2
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }