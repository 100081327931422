var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-titles p-3 border-bottom" }, [
      _c("h3", { staticClass: "text-themecolor" }, [
        _vm._v(_vm._s(_vm.trans("order.orders")) + "\n      "),
        _vm.orders
          ? _c("span", { staticClass: "card-subtitle" }, [
              _vm._v(
                _vm._s(
                  _vm.trans("general.total_result_found", {
                    count: _vm.orders.total
                  })
                )
              )
            ])
          : _c("span", { staticClass: "card-subtitle" }, [
              _vm._v(_vm._s(_vm.trans("general.no_result_found")))
            ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-info btn-sm pull-right",
            on: {
              click: function($event) {
                return _vm.$router.push("/home")
              }
            }
          },
          [
            _c("i", { staticClass: "fas fa-home" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(_vm._s(_vm.trans("general.home")))
            ])
          ]
        ),
        _vm._v(" "),
        _vm.orders.total && !_vm.showFilterPanel
          ? _c(
              "button",
              {
                staticClass: "btn btn-info btn-sm pull-right m-r-5",
                on: {
                  click: function($event) {
                    _vm.showFilterPanel = !_vm.showFilterPanel
                  }
                }
              },
              [
                _c("i", { staticClass: "fas fa-filter" }),
                _vm._v(" " + _vm._s(_vm.trans("general.filter")))
              ]
            )
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-40" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("transition", { attrs: { name: "fade" } }, [
              _vm.showFilterPanel
                ? _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-body" }, [
                      _vm.showFilterPanel
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-info btn-sm pull-right",
                              on: {
                                click: function($event) {
                                  _vm.showFilterPanel = !_vm.showFilterPanel
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.trans("general.hide")))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("h4", { staticClass: "card-title" }, [
                        _vm._v(_vm._s(_vm.trans("general.filter")))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("order.id")))
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filterOrdersForm.external_id,
                                  expression: "filterOrdersForm.external_id"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { name: "external_id" },
                              domProps: {
                                value: _vm.filterOrdersForm.external_id
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.filterOrdersForm,
                                    "external_id",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("order.name")))
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filterOrdersForm.name,
                                  expression: "filterOrdersForm.name"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { name: "name" },
                              domProps: { value: _vm.filterOrdersForm.name },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.filterOrdersForm,
                                    "name",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ])
                      ])
                    ])
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _vm.orders.total
                    ? _c("div", { staticClass: "table-responsive" }, [
                        _c(
                          "table",
                          { staticClass: "border", attrs: { width: "100%" } },
                          [
                            _c(
                              "tbody",
                              _vm._l(_vm.orders.data, function(order) {
                                return _c(
                                  "tr",
                                  [_c("order", { attrs: { order: order } })],
                                  1
                                )
                              }),
                              0
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.orders.total
                    ? _c("module-info", {
                        attrs: {
                          module: "product",
                          title: "module_info_title",
                          description: "module_info_description",
                          icon: "key"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("pagination-record", {
                    attrs: {
                      "page-length": _vm.filterOrdersForm.page_length,
                      records: _vm.orders
                    },
                    on: {
                      "update:pageLength": function($event) {
                        return _vm.$set(
                          _vm.filterOrdersForm,
                          "page_length",
                          $event
                        )
                      },
                      "update:page-length": function($event) {
                        return _vm.$set(
                          _vm.filterOrdersForm,
                          "page_length",
                          $event
                        )
                      },
                      updateRecords: _vm.getOrders
                    },
                    nativeOn: {
                      change: function($event) {
                        return _vm.getOrders($event)
                      }
                    }
                  })
                ],
                1
              )
            ])
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header bg-transparent" }, [
      _c("h2", [_vm._v("CURRENT ORDERS")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }